import React from "react";
import ReactDOM from "react-dom/client";
import "./global.css";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  Navigate,
  Route,
  RouterProvider,
  Routes,
  useNavigate,
} from "react-router-dom";
import Layout from "./Layout";
import Futures from "./pages/Futures";
import Register from "./pages/Register";
import Lever from "./pages/Lever";
import FutureLogin from "./pages/FutureLogin";
import LeverLogin from "./pages/LeverLogin";

// function RootBoundary() {
//   const error = useRouteError();
//
//   if (isRouteErrorResponse(error)) {
//     if (error.status === 404) {
//       return <div>This page doesn't exist!</div>;
//     }
//
//     if (error.status === 401) {
//       return <div>You aren't authorized to see this</div>;
//     }
//
//     if (error.status === 503) {
//       return <div>Looks like our API is down</div>;
//     }
//
//     if (error.status === 418) {
//       return <div>🫖</div>;
//     }
//   }
//   return <div>Something went wrong</div>;
// }

const withNavigate = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const navigate = useNavigate();
  return <Component {...props} navigate={navigate} />;
};

const router = createBrowserRouter([
  // {
  //   path: "/",
  //   Component: Futures,
  // },
  { path: "*", Component: withNavigate(Root) },
]);

function Root() {
  return (
    <Routes>
      <Route Component={Layout}>
        <Route
          index
          path={`${process.env.REACT_APP_PREFIX}`}
          Component={Futures}
        />
        <Route
          path={`${process.env.REACT_APP_PREFIX}future_leverage.html`}
          Component={Lever}
        />
        <Route
          path={`${process.env.REACT_APP_PREFIX}future_seminar.html`}
          Component={Register}
        />
        <Route
          path={`${process.env.REACT_APP_PREFIX}futures_login`}
          Component={FutureLogin}
        />
        <Route
          path={`${process.env.REACT_APP_PREFIX}lever_login`}
          Component={LeverLogin}
        />
        <Route path={`*`} element={<Navigate to={`/`} replace />} />
      </Route>
    </Routes>
  );
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
