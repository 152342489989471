import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useRecoilState } from "recoil";
import { showDialog } from "../state";

export default function MyModal() {
  const [isOpen, setIsOpen] = useRecoilState(showDialog);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      {/*<div className="fixed inset-0 flex items-center justify-center">*/}
      {/*  <button*/}
      {/*    type="button"*/}
      {/*    onClick={openModal}*/}
      {/*    className="rounded-md bg-black/20 px-4 py-2 text-sm font-medium text-white hover:bg-black/30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"*/}
      {/*  >*/}
      {/*    Open dialog*/}
      {/*  </button>*/}
      {/*</div>*/}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-2xl font-medium leading-6 text-primary text-center"
                  >
                    隱私權保護聲明及個人資料保護法告知事項
                  </Dialog.Title>
                  <div className="mt-4">
                    <div className="text-sm text-gray-500">
                      <div className="text-sm text-gray-500 overflow-scroll h-64">
                        隱私權保護聲明
                        <br />
                        <br />
                        親愛的客戶，歡迎您光臨元大期貨股份有限公司(以下簡稱本公司)網站，為了讓您能夠安心的使用本公司網站為您提供的各項服務，對於您個人的隱私權，本公司會絕對尊重並嚴格保護，我們在此向您說明本公司網站的隱私權保護政策，以及我們對您所提供任何資料的絕對保護與尊重。
                        <br />
                        <br />
                        個人資料之蒐集政策
                        <br />
                        <br />
                        當您參與本公司線上活動、網路調查、加入會員或其他相關服務時，因服務性質的不同，本公司可能會請您提供姓名、身分證字號、住址、電話、電子信箱或其他相關必要資料。除此之外，本公司也可能會保留您在上網瀏覽或查詢時，伺服器自行產生的相關記錄，包括但不限於您使用連線設備的IP位址、使用的瀏覽器、使用時間、瀏覽及點選資料紀錄等。請您注意，與本網站連結的其他網站，也可能蒐集您個人的資料，對於您主動提供其他網站的個人資料，將依相關法令及各該連結網站所揭露之隱私權保護政策蒐集、處理與利用，不適用本聲明，本公司亦不負擔任何連帶責任。
                        <br />
                        <br />
                        個人資料之儲存及保護
                        <br />
                        <br />
                        為保護您個人資料之隱密及完整性，本公司謹恪遵政府相關法令及資訊管理原則。您的個人資料會被嚴密保存於本公司或本公司所委託具有高度安全性、穩定性之資訊保管公司的資料儲存系統中；要取得您的任何資料，都必須在本公司訂定之資料授權範圍內，始可進行資料之取得與使用。此外，本公司已採用適當技術及組織安全措施，保障您的個人資料免遭遺失或任何非法處理。本公司不會將您的資料提供給本公司或本聲明所告知以外之任何人，惟經司法單位或其他主管機關依法並透過正式且合法之程序要求時，則不在此限。
                        <br />
                        <br />
                        個人資料之查詢、閱覽及刪除
                        <br />
                        <br />
                        除法令另有規定外，您可以依本公司指定之方式及程序，行使下列權利：向本公司查詢、請求閱覽或請求製給複製本，而本公司得酌收必要成本費用。
                        <br />
                        向本公司請求補充或更正，惟您應為適當之釋明。
                        <br />
                        向本公司請求停止蒐集、處理或利用及請求刪除，惟本公司因執行業務所必須者，得不依您的申請為之。
                        <br />
                        <br />
                        自我保護措施
                        <br />
                        <br />
                        請妥善保管您的憑證、密碼或任何個人資料，切勿將任何個人資料提供第三人。在您使用完本公司網站所提供的各項服務功能後，請務必記得登出帳戶，若您是與他人共享電腦或使用公用電腦，切記要關閉瀏覽器視窗，以防止他人讀取您的個人資料。
                        <br />
                        <br />
                        本聲明之修訂
                        <br />
                        <br />
                        本公司為因應社會環境變化、業務需求、科技技術之進步及法令規定之變更，將不定時修訂與公布本聲明。請您隨時參閱本公司網站之隱私權保護聲明，以保障您的權益。
                        <br />
                        <br />
                        隱私權與資訊安全宣告
                        <br />
                        <br />
                        為保護使用者個人資料，並維護網路隱私權，使用者如對本聲明，或與個人資料有關之事項有意見時，請利用電子郵件Futures@Yuanta.Com或客服專線(02)2326-1000/0800-333-338(僅供市話撥打)向本公司提出，本公司將提供進一步之說明。
                        <br />
                        <br />
                        著作權聲明
                        <br />
                        <br />
                        本公司保留本公司網站內容之一切法律權利，非經本公司授權使用或同意，此處資料均不得利用電子、機械、影印、錄音或任何形式、方法予以重製、轉載或製作衍生物等。
                        <br />
                        <br />
                        客戶資料共享服務
                        <br />
                        <br />
                        本公司及元大金控集團子公司(包含元大證券、元大人壽、元大銀行、元大投信)間，依「個人資料保護法」、「金融機構間資料共享指引」及其他相關法令規定，參與及提供客戶資料共享服務
                        (下稱本服務)，以提供優質金融商品與服務，在尊重客戶資料隱密性的前提下，提升客戶便利性及加強集團綜效、強化客戶與集團風險管理、促進金融機構間跨業合作並確保客戶權益。
                        <br />
                        <br />
                        共享資料目的：
                        <br />
                        <br />
                        本公司與元大金控集團子公司間得為辨識風險、強化客戶與集團風險管理，為提升客戶作業便利性，或為促進金融機構間跨業合作而共享資料。
                        <br />
                        <br />
                        共享資料範圍： <br />
                        <br />
                        甲、
                        本服務下共享之客戶資料係指經客戶同意或客戶於簽署各類契約文書中明文約定留存於本公司或元大金控集團子公司內，包括客戶之基本資料、身分核驗資料、帳戶資料、金融商品或服務之交易紀錄、風險資訊、認識客戶(KYC)資料及金融機構加值之資料、電子通訊歷程紀錄或其他經客戶與合作金融機構同意共享之資料(以下合稱客戶資料)。
                        <br />
                        <br />
                        乙、
                        客戶資料保護措施：客戶資料均被嚴密地保存在本公司及元大金控集團子公司的資料庫中。同時，本公司與元大金控集團子公司將依據各別內部管理規範以控管資料之存取，凡未經正當授權之人員，均不得接觸客戶資料。參與本服務之各金融機構間皆以安全機制進行資料傳輸的加密，以保護客戶個人資料安全。除以安全軟硬體設備進行資料傳輸外，並輔以安全之防護機制儲存密碼，另尚且加裝防火牆阻止第三人不法入侵或內部非法使用。
                        <br />
                        <br />
                        客戶權益維護：
                        <br />
                        <br />
                        客戶如有停止資料共享之要求，得隨時透過客戶服務中心等管道通知參與本服務之金融機構，各金融機構將於收受上開通知後停止共享資料。
                        <br />
                        本公司保有修訂客戶資料共享隱私權政策之權利，並於官方網站或其他公開管道揭露相關訊息，修訂時亦同；若仍有其他與資料共享之隱私權政策問題，可隨時洽詢本公司或元大金控集團子公司之客戶服務中心。
                        <br />
                        <br />
                        臺灣期貨交易所蒐集、處理暨利用個人資料告知書
                        <br />
                        <br />
                        立書人於本公司開立期貨交易帳戶，從事國內外期貨交易，立書人相關個人資料會因為交易與結算之需要，由本公司提供予臺灣期貨交易所股份有限公司（以下簡稱期交所）。爰本公司受期交所之委託，代期交所依個人資料保護法第
                        9 條規定告知立書人下列事項：
                        <br />
                        <br />
                        機關名稱：臺灣期貨交易所股份有限公司
                        <br />
                        <br />
                        蒐集之目的：係為期貨市場交易、結算、監理及稽核等特定目的蒐集。
                        <br />
                        個人資料之類別項目：姓名、出生年月日、身分證字號（或僑外投資身分編號）、職業、聯絡方式、往來金融機構帳號、期貨交易帳號、委任代為交易之代理人姓名、身分證字號、電話、聯絡人姓名及電話等開戶資料、期貨買賣委託紀錄、成交紀錄、國內外期貨市場違約紀錄以及結算交割紀錄（含立書人權益數、未平倉部位資料、有價證券抵繳保證金資料、支付公債利息資料等）及其他期貨交易與結算相關資料。
                        <br />
                        <br />
                        個人資料利用之期間：金融監督管理委員會許可期交所業務經營之存續期間。
                        <br />
                        <br />
                        個人資料利用之對象：期交所、臺灣證券交易所股份有限公司、財團法人中華民國證券櫃檯買賣中心、臺灣集中保管結算所股份有限公司、中華民國期貨業商業同業公會、財團法人證券投資人及期貨交易人保護中心、財團法人金融消費評議中心、稅務機關、司法檢調機關、金融監督管理委員會及其指定之機構，以及其他依法得向期交所取得立書人個人資料之機構。
                        <br />
                        <br />
                        個人資料利用之地區：臺灣。
                        <br />
                        <br />
                        個人資料利用之方式：書面、電子檔案。 <br />
                        <br />
                        其他：
                        <br />
                        <br />
                        （一）期交所為市場監理等執行職務所必須，期交所無法應立書人要求停止蒐集、處理、利用及刪除立書人個人資料。
                        <br />
                        <br />
                        （二）個人資料查詢、閱覽及製給複製本：
                        <br />
                        <br />
                        1.立書人開戶資料、期貨買賣委託紀錄、成交紀錄、結算交割紀錄及立書人國內外期貨市場違約紀錄，得於期交所營業時間內查詢、閱覽及請求製給複製本。
                        <br />
                        <br />
                        2.立書人其他交易資料原則上仍須至開戶交易之期貨商處請求。
                        <br />
                        <br />
                        （三）個人資料更正、補充：立書人個人資料若有錯誤，原則上仍須至開戶交易之期貨商處請求補正或更正。
                        <br />
                        <br />
                        立書人拒不提供個人資料，本公司及期交所將無法進行業務之必要審核與處理作業及其他相關服務，爰此，本公司將得拒絕受理與立書人之業務往來及其他相關事項之辦理與申請。
                        <br />
                        <br />
                        元大期貨個人資料保護法第八條事項受告知聲明書
                        <br />
                        <br />
                        本公司蒐集立書人個人資料，茲依據個人資料保護法(以下稱個資法)第8條第1項規定，向立書人告知下列事項，敬請詳閱：
                        <br />
                        蒐集之目的：
                        <br />
                        <br />
                        1.經營目的事業主管機關許可及營業登記項目或章程所定業務，包含期貨經紀、期貨顧問、槓桿交易商、證券交易輔助人等相關金融業務、其他經目的事業主管機關許可及符合本公司營業登記項目或章程所訂之業務。(法定特定目的項目編號:166、181、182)。
                        <br />
                        <br />
                        2.依法令規定及金融監理需要、依法定義務、依契約、類似契約或其他法律關係事務及行銷、客戶管理與服務等，所為個人資料之蒐集、處理及利用(法定特定目的項目編號:
                        040、059、063、069、090)。
                        <br />
                        <br />
                        3.為遵循美國海外帳戶稅收遵循法（Foreign Account Tax
                        Compliance Act）、防制洗錢及打擊資恐等管控措施。
                        <br />
                        <br />
                        蒐集之個人資料類別：
                        <br />
                        <br />
                        依照法務部公告「個人資料保護法之特定目的及個人資料之類別」，與業務經營有關之個人資料之類別」：(C001)辨識個人者、(C002)辨識財務者、(C003)政府資料中之辨識者/特徵類:(C011)個人描述、(C031)住家及設施、(C032)財產、（C033）移民情形、(C038)職業、（C041）法院、檢察署或其他審判機關或其他程序/教育、考選、技術或其他專業:（C052）資格或技術/受僱情形:（C061）現行之受僱情形/財務細節:
                        <br />
                        (C081)收入、所得、資產與投資、(C083)信用評等、(C086)票據信用等。
                        <br />
                        <br />
                        個人資料利用之期間、對象、地區、方式：
                        <br />
                        <br />
                        1.期間：於主管機關許可業務經營之存續期間內，符合下列要件之一者：
                        <br />
                        <br />
                        (1)個人資料蒐集之特定目的存續期間。
                        <br />
                        <br />
                        (2)依相關法令規定或契約約定之保存年限(如商業會計法等)。
                        <br />
                        <br />
                        (3)本公司因執行業務所必須之保存期間。
                        <br />
                        <br />
                        2.地區：獲主管機關許可經營及經營營業登記項目或章程所定之業務，其營業活動之相關地區及為達蒐集、處理及利用目的所必須使用之相關地區：包含本公司、本公司之分公司、與本公司有從屬關係之子公司或有控制關係之母公司暨其分公司或集團關係之公司、與本公司或前述公司因業務需要而訂有契約之機構或顧問等所在之地區、及國際傳輸個人資料需未受中央目的事業主管機關限制之接收者所在地。
                        <br />
                        <br />
                        3.對象：
                        <br />
                        <br />
                        (1)本公司、本公司之分公司、與本公司有從屬關係之子公司或有控制關係之母公司暨其分公司或集團關係之公司、或與本公司或前述公司因業務需要訂有契約關係或業務往來之機構(含共同行銷、合作推廣等)或顧問(如律師、會計師)、獲主管機關許可受讓證券商全部或部分業務之受讓人。
                        <br />
                        <br />
                        (2)金融監理或依法有調查權或依法行使公權力之機關、證券或期貨交易所、證券櫃檯買賣中心、集中保管結算所、金融商業同業公會、股票發行公司、交割銀行、臺灣票據交換所(發放股利)、臺灣總合股務公司等依法令授權辦理股務事務之相關機構及其他經目的事業主管機關指定，包含在業務經營上，與監督管理檢查、發行、買賣、徵信、交易、交割、股務等有關之相關機構，及對第1點所列利用對象有管轄權之金融監理機構與依法行使公權力之機關。
                        <br />
                        <br />
                        (3)其他立書人所同意之對象。
                        <br />
                        <br />
                        4.方式：以自動化機器或其他非自動化方式等合於法令規定所為之利用，包括但不限於：1.書面或電子2.國際傳輸等。
                        <br />
                        <br />
                        依據個資法第3條規定，立書人就本公司保有之個人資料得行使之權利及方式：
                        <br />
                        <br />
                        1.得向本公司行使之權利：
                        <br />
                        <br />
                        (1)向本公司查詢、請求閱覽或請求製給複製本，而本公司依法得酌收必要成本費用。
                        <br />
                        <br />
                        (2)向本公司請求補充或更正，惟依法立書人應為適當之釋明。
                        <br />
                        <br />
                        (3)向本公司請求停止蒐集、處理或利用及請求刪除，惟依法本公司因執行業務所必須者，得不依立書人請求為之。
                        <br />
                        <br />
                        2.行使權利之方式：以書面或其他日後可供證明之方式(如：書面、電子郵件、傳真或電子文件等)。
                        <br />
                        <br />
                        立書人不提供個人資料所致權益之影響
                        <br />
                        <br />
                        倘立書人拒不提供個人資料，本公司將無法進行業務之必要審核與處理作業及其他相關服務，爰本公司將得拒絕受理與立書人之業務往來及其他相關事項之辦理與申請；另本公司必須依美國海外帳戶稅收遵循法之規定將立書人帳戶列為「不合作帳戶」(Recalcitrant
                        Account)，如經合理期間內仍未獲立書人同意或立書人提供資料仍有不足，本公司得於符合法令規定之範圍內，採取其合理認為有必要之任何行動，以確保遵循美國海外帳戶稅收遵循法之規定。
                        <br />
                        <br />
                        此致 元大期貨股份有限公司
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 flex items-center justify-between px-4 py-3 sm:px-6">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      取消
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      同意
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
