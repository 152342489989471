import { ImageMap } from "@qiuz/react-image-map";
import { useRef, useState } from "react";
import Avatar, { coming } from "../utils";
import { Helmet } from "react-helmet-async";
import MyModal from "../components/MyModal";
import { useRecoilState } from "recoil";
import { showDialog } from "../state";
import axios from "axios";

const Register = () => {
  const tab1 = "./img/taipei.png";
  const tab2 = "./img/hsinchu.png";
  const tab3 = "./img/taichung.png";
  const tab4 = "./img/tainan.png";
  const tab5 = "./img/kaohsiung.png";
  const [show, setShow] = useState(0);
  const [isOpen, setIsOpen] = useRecoilState(showDialog);

  const mapFreeRegister = [
    {
      left: "59%",
      top: "80%",
      height: "12%",
      width: "19%",
      style: {
        // background: "rgba(255, 0, 0, .5)",
        cursor: "pointer",
      },
    },
  ];

  const mapArea = [
    {
      left: "6%",
      top: "18%",
      height: "14%",
      width: "10%",
      style: {
        // background: "rgba(255, 0, 0, .5)",
        cursor: "pointer",
      },
      onMouseOver: () => {
        setShow(0);
      },
    },
    {
      left: "16%",
      top: "18%",
      height: "14%",
      width: "10%",
      style: {
        // background: "rgba(255, 0, 0, .5)",
        cursor: "pointer",
      },
      onMouseOver: () => setShow(1),
    },
    {
      left: "26%",
      top: "18%",
      height: "14%",
      width: "10%",
      style: {
        // background: "rgba(255, 0, 0, .5)",
        cursor: "pointer",
      },
      onMouseOver: () => setShow(2),
    },
    {
      left: "36%",
      top: "18%",
      height: "14%",
      width: "10%",
      style: {
        // background: "rgba(255, 0, 0, .5)",
        cursor: "pointer",
      },
      onMouseOver: () => setShow(3),
    },
    {
      left: "46%",
      top: "18%",
      height: "14%",
      width: "12%",
      style: {
        // background: "rgba(255, 0, 0, .5)",
        cursor: "pointer",
      },
      onMouseOver: (e) => {
        setShow(4);
      },
    },
    {
      left: "72%",
      top: "17%",
      height: "60px",
      width: "23%",
      style: {
        // background: "rgba(255, 0, 0, .5)",
        cursor: "pointer",
      },
    },
  ];

  const Teachers = [
    {
      avatar: "",
      department: "元大投顧分析師",
      name: "顏承暉",
      title: "博士",
      body: `國立台灣大學經濟學系博士，現任職元大投顧分析師；主要專長
深入剖析全球金融市場方向、計量經濟、景氣循環；顏博士為少
數同時具有學術界以及實務界雙重肯定的經濟學家，同時亦有多
篇文章發表在國際期刊上，經濟見解鞭辟入里，經常被國內外平
面媒體所引用。`,
    },
    {
      avatar: "",
      department: "元大投信期貨信託部",
      name: "陳易廷",
      title: "基金經理人",
      body: `現任三檔期信ETF基金經理人，曾任職於元大投信理財事業
部理財顧問，並具備證券分析師與期貨分析師，擅長資產配
置、產品開發及趨勢研究，榮獲第13屆證券暨期貨金椽獎「
前瞻創新」-甲等獎。`,
    },
    {
      avatar: "",
      department: "元大投顧研究中心",
      name: "陳森林",
      title: "專業資深協理",
      body: `總體經濟研究、債券及外匯市場分析24年經驗，歷經銀行、證券、票券等不同金融業，從不同角度切入分析市場動向。`,
    },
    {
      avatar: "",
      department: "元大期貨高雄分公司",
      name: "鄭靜媚",
      title: "業務副總",
      body: `超過10年的期貨與證券市場經驗，擅長一對一的客製化金融服務，業務經驗豐富且廣受各大客層青睞，至今已累積有千位客戶的金融從業人員。`,
    },
    {
      avatar: "",
      department: "元大期貨通路暨顧問事業部",
      name: "曾崇銘",
      title: "分析師",
      body: `現任元大期貨通路暨顧問事業部，具有期貨營業員、證券營業員、期貨分析師（CFTA）中國期貨從業人員、香港證券及期貨從業人員【著作】股市的科學煉金術：程式交易全圖解(合著) 。`,
    },
    {
      avatar: "",
      department: "元大期貨通路暨顧問事業部",
      name: "丁長生",
      title: "分析師",
      body: `現任元大期貨通路暨顧問事業部專業講師，過去期權培訓課程經歷，包含電視、廣播媒體來賓，產學課程、台灣期交所、上海期交所期權推廣講師，在交易經驗的累積下，擅長選擇權策略應用。目前負責通路經營。`,
    },
    {
      avatar: "",
      department: "元大期貨新竹分公司",
      name: "吳昇威",
      title: "協理/分析師",
      body: `現職元大期貨新竹分公司經理人，擁有期貨分析師 (CFTA)證照，歷經業務部、數位頻道、稽核部皆管理階級，專長為背離操盤術、均線法則、期權組合策略，擁有客戶管理及期權實戰26年經驗。`,
    },
    {
      avatar: "",
      department: "元大期貨台中分公司",
      name: "李協承",
      title: "資深副理",
      body: `現任元大期貨台中分公司資深副理，曾任職銀行主管，因投資興趣加入元大期貨團隊，金融業資歷逾20年。對於產業研究、國際財經與國外期貨商品均有涉略。`,
    },
    {
      avatar: "",
      department: "元大期貨台南分公司",
      name: "簡廷伃",
      title: "副理",
      body: `曾任元大期貨台南分公司業務資深經理，在職11年，擅長以淺顯易懂的方式讓您更快了解期貨交易。`,
    },
    {
      avatar: "",
      department: "元大期貨經紀部",
      name: "楊瑋菱",
      title: "業務襄理",
      body: `現任元大期貨經紀部業務襄理，過去曾任豪宅設計師近十年，以長期接觸高端業主及在藝術品拍賣、挑選的經驗心得及眼界，轉換至金融業，融合不同於本科領域的專業，致力將人生活的美與財富的養成分享給大家。`,
    },
    {
      avatar: "",
      department: "元大期貨槓桿交易部",
      name: "廖得安",
      title: "資深經理",
      body: `差價契約CFD專業講師，2015年進入自營交易、2017年槓桿開業以來服務至今，每年均有大小講座數十場，樂於分享知識與經驗，擅長協助交易人透過差價契約CFD取得成功。`,
    },
    {
      avatar: "",
      department: "元大期貨槓桿交易部",
      name: "王士卿",
      title: "專業資深經理",
      body: `期貨元年(1994)進入業界，帶領團隊在南部紮根茁壯；2018年轉任槓桿部門，以多年市場經驗分享及交流，協助部門及投資人共同成長。`,
    },
  ];

  // Function to test string
  function isJSON(str) {
    try {
      JSON.stringify(JSON.parse(str));
      return true;
    } catch (e) {
      return false;
    }
  }

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [area, setArea] = useState("");
  const [isMember, setIsMember] = useState("");
  const [isOnline, setIsOnline] = useState("");
  const [agree, setAgree] = useState("");
  const [recaptcha, setRecaptcha] = useState("");

  const refCaptcha = useRef();
  const refReload = useRef();

  const submit = async (e) => {
    e.preventDefault();

    let formData = new FormData();

    formData.append("Name", name);
    formData.append("Email", email);
    formData.append("Mobile", mobile);
    formData.append("Area", area);
    formData.append("Is_Member", isMember);
    formData.append("Is_Online", isOnline);
    formData.append("Agree", agree === 1 ? "true" : "");
    formData.append("Captcha", recaptcha);

    await axios
      .post(`${process.env.REACT_APP_REGISTER}`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((r) => {
        if (r.data.status === false) {
          if (isJSON(r.data.msg)) {
            let msgs = JSON.parse(r.data.msg);
            let messages = "";
            msgs.filter((msg) => {
              messages += msg + "\r\n";
            });
            console.log(messages);
            alert(messages);
          } else {
            alert(r.data.msg);
          }
        }
        if (r.data.status) {
          alert(`報名成功`);
          refReload.current.src =
            process.env.REACT_APP_RECAPTCHA + "?" + Date.now();
          refCaptcha.current.value = "";
        }
      })
      .catch((e) => {
        console.log(e.toString());
      });
  };

  let windowObjectReference = null; // global variable
  function openRequestedTab(url, windowName) {
    if (windowObjectReference === null || windowObjectReference.closed) {
      windowObjectReference = window.open(url, windowName);
    } else {
      windowObjectReference.focus();
    }
  }

  return (
    <>
      <Helmet>
        <title>{`${process.env.REACT_APP_TITLE}`} - 講座論壇</title>
        <meta
          name={`description`}
          content={`${process.env.REACT_APP_DESCRIPTION}`}
        />
        <meta
          name={`og:title`}
          content={`${process.env.REACT_APP_TITLE} - 講座論壇`}
        />
      </Helmet>
      <div
        className={`bg-cover`}
        style={{
          backgroundImage: `url(./img/bg.png)`,
        }}
      >
        <MyModal />
        <ImageMap
          src={`./img/banner.jpg`}
          map={mapFreeRegister}
          className={`pt-[40px] lg:pt-0`}
          onMapClick={(e) => {
            window.scrollTo({
              top: 3900,
              behavior: "smooth",
            });
          }}
        />
        {/*<div className={`relative`}>*/}
        {/*  <img src="./img/banner.jpg" className={`w-full`} alt="banner" />*/}
        {/*  <a*/}
        {/*    href=""*/}
        {/*    className={`absolute -bottom-[40px] right-[120px] w-[150px] h-[150px]`}*/}
        {/*  >*/}
        {/*    <img src="./img/free_register.png" alt="免費報名" />*/}
        {/*  </a>*/}
        {/*</div>*/}
        <div className={`container m-auto pt-10 pr-4 pl-4 max-w-5xl`}>
          <img src="./img/a1_0.png" alt="" />
        </div>
        <div className="container m-auto pt-10 pl-4 pr-4 max-w-5xl">
          <img src="./img/a2_0.png" alt="3大主題" />
        </div>
        <div className="container m-auto pt-10 pl-4 pr-4 max-w-5xl">
          <ImageMap
            src={tab1}
            map={mapArea}
            className={`${show !== 0 ? "hidden" : ""}`}
            onMapClick={(area, index) => {
              const link = document.querySelector("a[id='openIt']");
              link.addEventListener(
                "click",
                (event) => {
                  openRequestedTab(link.href);
                  event.preventDefault();
                },
                false,
              );
              link.click();
            }}
          />
          <ImageMap
            src={tab2}
            map={mapArea}
            className={`${show !== 1 ? "hidden" : ""}`}
            onMapClick={(area, index) => {
              const link = document.querySelector("a[id='openIt']");
              link.addEventListener(
                "click",
                (event) => {
                  openRequestedTab(link.href);
                  event.preventDefault();
                },
                false,
              );
              link.click();
            }}
          />
          <ImageMap
            src={tab3}
            map={mapArea}
            className={`${show !== 2 ? "hidden" : ""}`}
            onMapClick={(area, index) => {
              const link = document.querySelector("a[id='openIt']");
              link.addEventListener(
                "click",
                (event) => {
                  openRequestedTab(link.href);
                  event.preventDefault();
                },
                false,
              );
              link.click();
            }}
          />
          <ImageMap
            src={tab4}
            map={mapArea}
            className={`${show !== 3 ? "hidden" : ""}`}
            onMapClick={(area, index) => {
              const link = document.querySelector("a[id='openIt']");
              link.addEventListener(
                "click",
                (event) => {
                  openRequestedTab(link.href);
                  event.preventDefault();
                },
                false,
              );
              link.click();
            }}
          />
          <ImageMap
            src={tab5}
            map={mapArea}
            className={`${show !== 4 ? "hidden" : ""}`}
            onMapClick={(area, index) => {
              const link = document.querySelector("a[id='openIt']");
              link.addEventListener(
                "click",
                (event) => {
                  openRequestedTab(link.href);
                  event.preventDefault();
                },
                false,
              );
              link.click();
            }}
          />
          <a href={`./論壇時程表.jpg`} id={`openIt`}></a>
        </div>
        <div className="container m-auto pt-10 pl-4 pr-4 max-w-5xl">
          <img src="./img/teacher.png" alt="重金" />
          <div className="grid gap-4 grid-cols-1 md:grid-cols-2 pt-4 pb-10">
            <div className={`md:col-span-2`}>
              <div className="flex items-start md:items-center justify-center bg-sky-100 rounded-lg p-4">
                <img
                  src="./img/avatar1.png"
                  alt="胡睿涵"
                  className={`w-[80px] md:w-[146px]`}
                />
                <div className={`ml-4 mr-4 leading-0 lg:leading-10`}>
                  <p className={`text-primary`}>
                    元大投顧 董事長
                    <br />
                    <span className={`text-xl md:text-2xl`}>胡睿涵</span> 博士
                  </p>
                  <p className={`mt-2 text-xs md:text-lg`}>
                    曾為知名財經主播，現成功跨界並任職全台最大證券集團下元大投顧董事長。業界公認「美麗、知性與感性」於一身的女性經濟觀察專家，無論是在
                    原物料行情、國際產業、科技電子等各項產業領域，皆有精闢的見解。著有四本暢銷財金書籍，結合多年主持財經節目的經驗，為投資人及交易人關
                    心的台股脈動及世界局勢，提供更多、更深入的觀點！
                  </p>
                </div>
              </div>
            </div>
            {Teachers.map((item, index) => (
              <Avatar
                key={index}
                avatar={`./img/avatar${index + 2}.png`}
                department={item.department}
                name={item.name}
                title={item.title}
                body={item.body}
              />
            ))}
          </div>
        </div>
        <div className="container m-auto pt-10 pl-4 pr-4 pb-40 max-w-5xl">
          <div className={`ring-orange-500 ring-4 h-auto rounded-lg bg-white`}>
            <img
              src="./img/x1.png"
              alt="論壇報名"
              className={`absolute w-60 m-auto left-0 right-0`}
            />
            <div className={`pt-20 ml-4 mr-4 text-2xl`}>
              本活動歡迎對論壇課程主題有興趣的產業人士參加，採現場聆聽及線上參與兩種方式。
              <br />
              請確實填寫聯絡資訊，資料送出後的兩日內會先收到報名成功訊息。
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 ml-4 mr-4 mt-10">
              <div className={`ml-4 mr-4`}>
                <div className={`flex items-center mb-4`}>
                  <input
                    type="text"
                    name={`name`}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className={`border-b-2 border-b-orange-600 w-full text-2xl py-4 px-4`}
                    placeholder={`＊ 姓名 (真實姓名)`}
                  />
                </div>

                <div className={`flex items-center mb-4`}>
                  <input
                    type="text"
                    name={`mobile`}
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    className={`border-b-2 border-b-orange-600 w-full text-2xl px-4 py-4`}
                    placeholder={`＊ 電話`}
                  />
                </div>

                <div className={`flex items-center mb-4`}>
                  <input
                    type="email"
                    name={`email`}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className={`border-b-2 border-b-orange-600 w-full text-2xl px-4 py-4`}
                    placeholder={`＊ 電子郵件`}
                  />
                </div>

                <div className="mb-4">
                  <div
                    className={`bg-orange-600 text-white rounded-lg pl-4 pr-4 mb-4 text-xl w-60`}
                  >
                    是否為元大期貨客戶
                  </div>
                  <div className="flex flex-col text-xl">
                    <div className={`mb-2 flex items-center`}>
                      <input
                        type="radio"
                        name={`is_member`}
                        value={1}
                        onClick={(e) => setIsMember(1)}
                        className={`w-6 h-6`}
                      />
                      <p className={`ml-4`}>是，我在元大期貨有帳戶</p>
                    </div>
                    <div className={`flex items-center`}>
                      <input
                        type="radio"
                        name={`is_member`}
                        value={0}
                        onClick={(e) => setIsMember(0)}
                        className={`w-6 h-6`}
                      />
                      <p className={`ml-4`}>否，尚未有元大期貨有帳戶</p>
                    </div>
                  </div>
                </div>

                <div className="mb-4">
                  <div
                    className={`bg-orange-600 text-white rounded-lg pl-4 pr-4 mb-4 text-xl w-60`}
                  >
                    上課方式
                  </div>
                  <div className="flex flex-col text-xl">
                    <div className={`mb-2 flex items-center`}>
                      <input
                        type="radio"
                        name={`is_online`}
                        value={0}
                        onClick={(e) => setIsOnline(0)}
                        className={`w-6 h-6`}
                      />
                      <p className={`ml-4`}>實體現場！數量有限手刀搶下</p>
                    </div>
                    <div className={`flex items-center`}>
                      <input
                        type="radio"
                        name={`is_online`}
                        value={1}
                        onClick={(e) => setIsOnline(1)}
                        className={`w-6 h-6`}
                      />
                      <p className={`ml-4`}>線上聆聽!請提前發送連結給我</p>
                    </div>
                  </div>
                </div>

                <div className="mb-4">
                  <div className={`flex items-center`}>
                    <input
                      ref={refCaptcha}
                      type="text"
                      name={`recaptcha`}
                      value={recaptcha}
                      onChange={(e) => setRecaptcha(e.target.value)}
                      placeholder={`請輸入認證碼`}
                      className={`px-4 py-2 rounded-lg text-2xl ring-1 w-1/2 mr-2`}
                    />
                    <img
                      ref={refReload}
                      src={`${process.env.REACT_APP_RECAPTCHA}`}
                      alt={`recaptcha`}
                      className={`ml-2 mr-2`}
                    />
                    <button
                      onClick={(e) => {
                        refReload.current.src =
                          process.env.REACT_APP_RECAPTCHA + "?" + Date.now();
                        refCaptcha.current.value = "";
                      }}
                    >
                      <img src="./img/circle.png" alt="" className={`w-16`} />
                    </button>
                  </div>
                </div>
              </div>
              <div>
                <div
                  className={`text-2xl text-white bg-orange-600 rounded-lg w-full md:w-1/2 p-2`}
                >
                  報名地區/時間/地址
                </div>
                <div>
                  <div className={`mt-4 mb-4`}>
                    <div className={`flex items-center`}>
                      <input
                        type="radio"
                        name={`area`}
                        value={`1`}
                        className={`w-[25px] h-[25px]`}
                        onClick={(e) => setArea(1)}
                      />
                      <div
                        className={`ml-4 mr-4 px-2 md:px-6 leading-10 text-xl text-white`}
                        style={{
                          backgroundColor: "#E71D72",
                        }}
                      >
                        台北
                      </div>
                      <div className={`text-xl`}>1/16 (二) 14:30 ~ 16:30</div>
                    </div>
                    <div className={`px-10`}>地點：台大醫院國際會議中心</div>
                    <div className={`px-10`}>
                      地址：台北市中正區徐州路2號301會議中心
                    </div>
                  </div>
                  <div className={`mt-4 mb-4`}>
                    <div className={`flex items-center`}>
                      <input
                        type="radio"
                        name={`area`}
                        value={`2`}
                        className={`w-[25px] h-[25px]`}
                        onClick={(e) => setArea(2)}
                      />
                      <div
                        className={`ml-4 mr-4 px-2 md:px-6 leading-10 text-xl text-white`}
                        style={{
                          backgroundColor: "#F28B1C",
                        }}
                      >
                        新竹
                      </div>
                      <div className={`text-xl`}>1/17 (三) 19:00 ~ 20:30</div>
                    </div>
                    <div className={`px-10`}>地點：元大期貨 新竹分公司</div>
                    <div className={`px-10`}>
                      地址：新竹市光復路一段373號B1之1
                    </div>
                    <div className={`mt-4 mb-4`}>
                      <div className={`flex items-center`}>
                        <input
                          type="radio"
                          name={`area`}
                          value={`3`}
                          className={`w-[25px] h-[25px]`}
                          onClick={(e) => setArea(3)}
                        />
                        <div
                          className={`ml-4 mr-4 px-2 md:px-6 leading-10 text-xl text-white`}
                          style={{
                            backgroundColor: "#004E9F",
                          }}
                        >
                          台中
                        </div>
                        <div className={`text-xl`}>1/12 (五) 14:30 ~ 16:00</div>
                      </div>
                      <div className={`px-10`}>地點：台中商旅</div>
                      <div className={`px-10`}>
                        地址：台中市西屯區臺灣大道三段593號
                      </div>
                    </div>
                    <div className={`mt-4 mb-4`}>
                      <div className={`flex items-center`}>
                        <input
                          type="radio"
                          name={`area`}
                          value={`4`}
                          className={`w-[25px] h-[25px]`}
                          onClick={(e) => setArea(4)}
                        />
                        <div
                          className={`ml-4 mr-4 px-2 md:px-6 leading-10 text-xl text-white`}
                          style={{
                            backgroundColor: "#58C3EA",
                          }}
                        >
                          台南
                        </div>
                        <div className={`text-xl`}>1/15 (一) 18:30 ~ 20:00</div>
                      </div>
                      <div className={`px-10`}>地點：元大期貨 台南分公司</div>
                      <div className={`px-10`}>
                        地址：台南市民生路一段165號5樓會議室
                      </div>
                    </div>
                    <div className={`mt-4 mb-4`}>
                      <div className={`flex items-center`}>
                        <input
                          type="radio"
                          name={`area`}
                          value={`5`}
                          className={`w-[25px] h-[25px]`}
                          onClick={(e) => setArea(5)}
                        />
                        <div
                          className={`ml-4 mr-4 px-2 md:px-6 leading-10 text-xl text-white`}
                          style={{
                            backgroundColor: "#00A990",
                          }}
                        >
                          高雄
                        </div>
                        <div className={`text-xl`}>1/18 (四) 14:30 ~ 16:00</div>
                      </div>
                      <div className={`px-10`}>地點：路易莎 (高雄龍德門市)</div>
                      <div className={`px-10`}>
                        地址：高雄市鼓山區龍德路409號
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`p-4 md:p-8`}>
              <div className="flex items-center">
                <div>
                  <input
                    type="checkbox"
                    className={`w-6 h-6`}
                    onClick={(e) => {
                      setAgree(e.target.checked ? 1 : 0);
                    }}
                    value={`1`}
                  />
                </div>
                <div className={`text-xl ml-2`}>
                  隱私權保護聲明及個人資料保護法告知事項{" "}
                  <span className={`text-red-600`}>＊必填</span>
                </div>
              </div>
            </div>
            <div className={`pl-8 pr-8 leading-8`}>
              <p>
                您所提供的資料，我們僅會基於您的申請事項之目的及範圍，於業務所需執行期間，在本公司所在地區以合理方式，蒐集、處理、利用您所留下之姓名、電話等個人資料，而在您點選「確認送出」時表示您對前述內容及本公司之
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    setIsOpen(!isOpen);
                  }}
                  className={`cursor-pointer text-primary underline`}
                >
                  隱私權保護聲明及個人資料保護法告知事項
                </a>
                已充分瞭解並同意。
              </p>
            </div>
            <div className={`flex items-center justify-center p-8`}>
              <button onClick={submit}>
                <img src="./img/submit.png" alt="submit" />
              </button>
            </div>
            <div className={`border-b-2 border-orange-500 mr-8 ml-8`}></div>
            <div className={`flex items-center justify-center p-8`}>
              <div className={`leading-8`}>
                注意事項 <br />
                1. 主辦單位保留議程變動之權利。
                <br />
                2.
                活動全程免費，因應市場、法令變動或其他不可抗力、不可歸責主辦單位之事由（例如天災、疫情），主辦單位保有修改、終止、延期本活動之權利，並於網頁公告，不另行通知。
                <br />
                3.勾選實體參與者，將於活動前兩日內，發送論壇課程相關內容資訊，至您報名填寫之E-mail信箱，屆時請留意相關訊息，我們期待與您相見。
                <br />
                4.勾選線上參與者，將於活動前兩日內，發送論壇課程相關內容資訊及上課連結至您報名填寫之E-mail信箱，屆時請留意相關訊息。
                <br />
                5.如有活動問題，週一至週五 08:00-17:00 洽活動承辦小組
                蘇小姐(02-2711-6000#7213)
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
