import {
  atom,
  selector,
  selectorFamily,
  useSetRecoilState,
  waitForNone,
} from "recoil";

export const showDialog = atom({
  key: "showDialog",
  default: false,
});

export const showDialog2 = atom({
  key: "showDialog2",
  default: false,
});
