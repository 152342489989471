import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { useRecoilState } from "recoil";
import { showDialog2 } from "../state";
import { ImageMap } from "@qiuz/react-image-map";
import Tab1 from "../images/開戶頁籤1_0.png";
import Tab2 from "../images/開戶頁籤2_0.png";
export default function MyModal2() {
  const [show, setShow] = useState(0);
  const [isOpen, setIsOpen] = useRecoilState(showDialog2);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  let windowObjectReference = null; // global variable
  function openRequestedTab(url, windowName) {
    if (windowObjectReference === null || windowObjectReference.closed) {
      windowObjectReference = window.open(url, windowName);
    } else {
      windowObjectReference.focus();
    }
  }

  const OpenIt = (id) => {
    const selector = "a[id='" + id + "']";
    //console.log(selector);
    const link = document.querySelector(selector);
    //console.log(link);
    link.addEventListener(
      "click",
      (event) => {
        openRequestedTab(link.href);
        event.preventDefault();
      },
      false,
    );
    link.click();
  };

  const tabArea = [
    {
      top: "0%",
      left: "3%",
      height: "12%",
      width: "27%",
      style: {
        // background: "rgba(0, 255, 0, .5)",
        cursor: "pointer",
      },
      onMouseOver: (e) => {
        setShow(0);
      },
    },
    {
      top: "0%",
      left: "32%",
      height: "12%",
      width: "27%",
      style: {
        // background: "rgba(0, 255, 0, .5)",
        cursor: "pointer",
      },
      onMouseOver: (e) => {
        setShow(1);
      },
    },
    {
      top: "78%",
      left: "33.5%",
      height: "12%",
      width: "33%",
      style: {
        // background: "rgba(0, 255, 0, .5)",
        cursor: "pointer",
      },
    },
  ];

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-2xl font-medium leading-6 text-primary text-center"
                  >
                    {/*隱私權保護聲明及個人資料保護法告知事項*/}
                  </Dialog.Title>
                  <div className="mt-4">
                    <ImageMap
                      src={Tab1}
                      map={tabArea}
                      className={`${show !== 0 ? "hidden" : ""}`}
                      onMapClick={(area, index) => {
                        if (index === 2) {
                          OpenIt(`id1`);
                        }
                      }}
                    />
                    <a
                      id={`id1`}
                      href={`https://www.yuantafutures.com.tw/openaccount_01`}
                    ></a>
                    <ImageMap
                      src={Tab2}
                      map={tabArea}
                      className={`${show !== 1 ? "hidden" : ""}`}
                      onMapClick={(area, index) => {
                        if (index === 2) {
                          OpenIt(`id2`);
                        }
                      }}
                    />
                    <a
                      id={`id2`}
                      href={`https://www.yuantafutures.com.tw/tradeplatform`}
                    ></a>
                  </div>

                  <div className="mt-4 flex items-center justify-center px-4 py-3 sm:px-6">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      關閉
                    </button>
                    {/*<button*/}
                    {/*  type="button"*/}
                    {/*  className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"*/}
                    {/*  onClick={closeModal}*/}
                    {/*>*/}
                    {/*  同意*/}
                    {/*</button>*/}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
