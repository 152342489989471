import K1 from "../images/futures/kv_前景.png";
import K2 from "../images/futures/kv_title.png";
import K3 from "../images/futures/kv_bg.png";

const FutureKv = () => {
  return (
    <div className={`pt-[40px] md:pt-0 lg:pt-0`}>
      {K1 && K2 && K3 && (
        <div
          className={`bg-cover bg-center bg-no-repeat`}
          style={{
            backgroundImage: `url(${K3})`,
          }}
        >
          <div className="relative">
            <img
              src={K2}
              alt="動物？"
              className={`m-auto left-0 right-0 animate__animated animate__zoomIn`}
            />
            <img
              src={K1}
              alt="cloud"
              className={`absolute bottom-0 animate__animated animate__fadeInUp w-full`}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default FutureKv;
