import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { showDialog2 } from "../state";
import { coming } from "../utils";

const StickyFooter = () => {
  let location = useLocation();
  const [isOpen, setIsOpen] = useRecoilState(showDialog2);
  const [action, setAction] = useState(
    `${process.env.REACT_APP_PREFIX}futures_login`,
  );

  useEffect(() => {
    if (location.pathname === "/") {
      setAction(`${process.env.REACT_APP_PREFIX}futures_login`);
    } else {
      setAction(`${process.env.REACT_APP_PREFIX}lever_login`);
    }
  }, [location.pathname]);
  return (
    <>
      <div className={`fixed bottom-0 md:hidden z-50`}>
        <div className="flex items-center">
          <div>
            <a onClick={(e) => setIsOpen(true)}>
              <img src="./img/f1.png" alt="" />
            </a>
          </div>
          <div>
            <Link
              // to={action}
              onClick={(e) => alert(coming)}
            >
              <img src="./img/f2.png" alt="" />
            </Link>
          </div>
          <div>
            <a onClick={(e) => alert(coming)}>
              <img src="./img/f3.png" alt="" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default StickyFooter;
