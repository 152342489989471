import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { showDialog2 } from "../state";
import { coming } from "../utils";

const StickyTop = () => {
  const [isOpen, setIsOpen] = useRecoilState(showDialog2);
  let location = useLocation();
  const [action, setAction] = useState(
    `${process.env.REACT_APP_PREFIX}futures_login`,
  );

  useEffect(() => {
    if (location.pathname === "/") {
      setAction(`${process.env.REACT_APP_PREFIX}futures_login`);
    } else {
      setAction(`${process.env.REACT_APP_PREFIX}lever_login`);
    }
  }, [location.pathname]);

  return (
    <>
      <a
        onClick={(e) => setIsOpen(true)}
        className={`hidden md:block z-40 fixed top-[100px] right-0 cursor-pointer`}
      >
        <img src="./img/sidebar-1.png" alt="" />
      </a>
      <Link
        onClick={(e) => alert(coming)}
        // to={action}
        className={`hidden md:block z-40 fixed top-[250px] right-0 cursor-pointer`}
      >
        <img src="./img/sidebar-2.png" alt="" />
      </Link>{" "}
      <a
        onClick={(e) => alert(coming)}
        className={`hidden md:block z-40 fixed top-[400px] right-0 cursor-pointer`}
      >
        <img src="./img/sidebar-3.png" alt="" />
      </a>
    </>
  );
};
export default StickyTop;
