import { Helmet } from "react-helmet";
import FutureKv from "../components/FutureKv";
import BG from "../images/futures/fbg.jpg";
import T from "../images/title1_0.png";
import { useState } from "react";
import { ArrowDownCircleIcon } from "@heroicons/react/16/solid";
import C1 from "../images/futures/外期對照表.png";

const FutureLogin = () => {
  const [showArticle, setShowArticle] = useState(false);
  return (
    <>
      <Helmet>
        <title>{`${process.env.REACT_APP_TITLE}`} - 外期交易查詢</title>
        <meta
          name={`description`}
          content={`${process.env.REACT_APP_DESCRIPTION}`}
        />
        <meta
          name={`og:title`}
          content={`${process.env.REACT_APP_TITLE} - 外期交易查詢`}
        />
      </Helmet>
      <FutureKv />
      <div
        className={`bg-center bg-cover`}
        style={{
          backgroundImage: `url(${BG})`,
        }}
      >
        <div className="container pt-16 max-w-5xl m-auto pl-4 pr-4 pb-10">
          <div className={`flex items-center justify-center pt-20`}>
            <div
              className={`max-w-5xl rounded-2xl shadow relative border-4 border-white`}
              style={{
                backgroundColor: "#EA5F00",
              }}
            >
              <div className={`absolute -top-12 w-full`}>
                <img src={T} alt="" className={`m-auto w-[360px]`} />
              </div>
              <div className={`p-4 pt-14 lg:p-20`}>
                <div className={`pb-4`}>
                  <input
                    type="text"
                    className={`rounded-lg shadow w-full px-3 py-4`}
                    placeholder={`請輸入身分證字號`}
                  />
                </div>
                <div className={`pb-4`}>
                  <input
                    type="text"
                    className={`rounded-lg shadow w-full px-3 py-4`}
                    placeholder={`請輸入網路交易密碼`}
                  />
                </div>
                <div className={`flex items-center`}>
                  <div>
                    <input
                      type="text"
                      className={`rounded-lg shadow px-3 py-4`}
                      placeholder={`請輸入認證碼`}
                    />
                  </div>
                  <div
                    className={`text-lg text-gray-300 bg-gray-800 p-2 ml-4 mr-4`}
                  >
                    1688
                  </div>
                  {/* <img*/}
                  {/*  ref={refReload}*/}
                  {/*  src={`${process.env.REACT_APP_RECAPTCHA}`}*/}
                  {/*  alt={`recaptcha`}*/}
                  {/*  className={`ml-2 mr-2`}*/}
                  {/*/>*/}
                  <button
                    onClick={(e) => {
                      // refReload.current.src =
                      //   process.env.REACT_APP_RECAPTCHA + "?" + Date.now();
                      // refCaptcha.current.value = "";
                    }}
                  >
                    <img src="./img/circle.png" alt="" className={`w-16`} />
                  </button>
                </div>
                <div
                  className={`flex items-center w-full justify-center pt-8 pb-8`}
                >
                  <button
                    className={`px-2 py-2 rounded-lg text-white pl-4 pr-4`}
                    style={{
                      backgroundColor: "#FBB03B",
                    }}
                  >
                    立即查詢
                  </button>
                </div>
                <div className={`text-white leading-relaxed`}>
                  非元大期貨客戶？
                  <a
                    href="https://www.yuantafutures.com.tw/openaccount_01"
                    target={`_blank`}
                    className={`underline font-bold`}
                  >
                    立即期貨線上開戶
                  </a>
                  <br />
                  若有疑問，請洽元大期貨24小時客服專線0800-333-338
                  <br />
                  註：本活動網頁不適用Internet Explorer11(含)以下版本
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`p-10 bg-gradient-to-r from-cyan-500 to-blue-500`}>
          <a
            className={`hover:cursor-pointer inline text-center`}
            onClick={(e) => {
              e.preventDefault();
              setShowArticle(!showArticle);
            }}
          >
            <h3
              className={`text-white text-3xl text-center inline-flex w-full items-center justify-center`}
            >
              活動注意事項{" "}
              <ArrowDownCircleIcon className={`h-8 w-8 animate-pulse ml-4`} />
            </h3>
          </a>

          <article
            className={`${
              showArticle ? "" : "hidden"
            } pt-20 prose lg:prose-xl text-white max-w-none pb-20`}
          >
            <ul>
              <li>
                活動資格：
                <ol>
                  <li>本活動以每人(ID歸戶)作為交易量統計及獎品兌換單位。</li>
                  <li>
                    新開戶定義(以ID歸戶計算)-
                    2024/01/01-01/31期間於元大期貨或所屬
                    IB新開立帳戶者，首次交易即為符合資格。
                  </li>
                  <li>
                    靜止戶-已於元大期貨或所屬IB開立期貨帳戶，且2023/10/01-2024/01/21期間從未交易過活動指定交易所之期權商品者，即符合活動資格。
                  </li>
                  <li>
                    本活動資格包含員工戶，但不包含法人戶以及中華民國稅法規定非中華民國境內居住之個戶(即在中華民國境內居住未達183天之本國人及外國人)。
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  活動商品：國外指定交易所(CME、SGX、HKEX、JPX與EUREX)全期貨與選擇權商品。
                </p>
              </li>
              <li>
                <p>活動計算說明：</p>

                <ol>
                  <li>
                    龍年大發抽黃金牌
                    <ul>
                      <li>
                        活動期間，指定活動商品交易10口，即獲得一次抽獎機會，每ID最多獲得乙次抽獎機會，且每ID限中獎乙次。
                      </li>
                      <li>
                        統一於隔月10日前公告於本公司活動網頁中獎名單，抽出兩名獲得元大期貨訂製-龍年黃金牌(價值12,600元)。
                      </li>
                    </ul>
                  </li>
                  <li>
                    龍 Time no see
                    <ul>
                      <li>
                        1)
                        活動期間，新戶或靜止戶交易1口，即獲得元大期貨訂製-悠遊卡200元。新開戶資格-2024/01/01-01/31期間於元大期貨或所屬IB完成開立期貨帳戶者，並首次交易者。-靜止戶資格-已有元大期貨或所屬IB開立帳戶，且2023/10/01-2024/01/21從未交易活動商品者。
                      </li>
                      <li>2) 每ID交易口數歸戶合計計算，且每ID限兌領乙次。</li>
                      <li>
                        3) 元大期貨訂製
                        悠遊卡200元於活動結束後(2024年2月)由所屬業代進行聯繫，客戶需填寫得獎確認單後，於2023年3月底前寄至客戶指定地址。
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p>龍騰步步高 好禮龍厚哩</p>
                    <ul>
                      <li>
                        活動期間，依照所有戶的交易狀況，可獲得累贈不累計的交易獎勵。
                        範例：交易口數達第一步贈【百貨禮券2,000元】+
                        【元大期貨訂製-悠遊卡200元】；交易口數達第二步再累贈【百貨禮券5,000元】，第一步、第二步禮品皆可獲得，以此類推，且每ID限兌領乙次。
                        <img src={C1} alt="" />
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p>
                      龍王加碼賽
                      交易龍王的指定商品，交易量最多者即可獲得龍王對應的獎勵金
                    </p>
                  </li>
                  <li>
                    <p>
                      美龍王指定商品:CME的所有選擇權，交易量最多者獲百貨禮券50,000元
                    </p>
                  </li>
                  <li>
                    <p>
                      日龍王指定商品:日盤的所有商品，交易量最多者獲百貨禮券30,000元
                    </p>
                  </li>
                  <li>
                    <p>
                      新龍王指定商品:SGX富台指，交易量最多者獲百貨禮券30,000元
                    </p>
                  </li>
                  <li>
                    <p>
                      歐龍王指定商品:EUREX微藍籌指數、EUREX微DAX指數，交易量最多者獲百貨禮券10,000元
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>贈品兌獎說明：</p>
                <ol>
                  <li>
                    百貨紙本禮券-活動結束後結算獲獎資格，依符合獲獎資格配發指定面額之禮券，並將於活動結束後(2024年2月)由所屬業代進行聯繫，客戶需填寫得獎確認單後，於2023年3月底前寄至客戶指定地址。
                  </li>
                  <li>
                    元大期貨訂製悠遊卡200元-於活動結束後(2024年2月)由所屬業代進行聯繫，客戶需填寫得獎確認單後，於2023年3月底前寄至客戶指定地址。
                  </li>
                </ol>
              </li>
              <li>
                <p>其它注意事項：</p>

                <ol>
                  <li>
                    本活動電子禮券與百貨或超市禮券之贈品使用方式與相關限制，悉依該獎項所載附之使用說明及相關須知為準，若有疑問請洽商品廠商或服務提供者，如因使用本獎項而引起之爭議、糾紛、損害賠償，由商品廠商或服務提供者負責。
                  </li>
                  <li>
                    本活動依中華民國稅法規定，得獎人如在中華民國境內有固定營業場所之營利事業，或為中華民國境內居住之個人，且獎項超過新台幣2萬元，本公司將代扣繳10%所得稅。如非中華民國境內居住之個人，或在中華民國境內無固定營業場所之營利事業，不論得獎人所得之金額，將代扣繳20%稅金。
                  </li>
                  <li>
                    參加本活動所提供之個人資料，視同個人資料保護法授權同意可做本公司於本活動之得獎資料核對、領獎聯絡通知、獎品兌換領取等使用，無法提供個人資料的參加者或得獎者視同放棄獎項，不再另行通知，亦不補發。
                  </li>
                  <li>
                    中獎人(納稅義務人)於本公司(扣繳義務人)之全年中獎總金額超過新台幣1,000
                    元(含)以上，年底時元大期貨將依稅法相關規定辦理開立扣繳憑單。
                  </li>
                  <li>
                    本活動之贈品不得轉讓，不得要求更換其他款式、規格或以現金折抵贈品價格，亦不適用其他促銷專案，本公司就活動獎項內容得依實際狀況斟酌調整，並保留隨時修改、變更、終止或暫停本活動及替換其他等值贈品之權利，得獎者應配合元大期貨相關公關活動宣傳，若有任何疑問，請洽元大期貨24小時客服專線
                    0800-333-338。
                  </li>
                  <li>
                    本活動係以客戶實際成交資料為統計依據，參加活動者需自負盈虧，報名前應先了解相關法令及風險預告書，且投資具有一定之風險，參加者不得以不知其規定為由，提出任何主張，亦不得請求活動期間產生損失之賠償。
                    <br />
                  </li>
                </ol>
              </li>
            </ul>
          </article>
        </div>
      </div>
    </>
  );
};
export default FutureLogin;
