import {useInView} from 'react-intersection-observer';

const Box = ({children, animate}) => {
    const [ref, inView] = useInView({
        triggerOnce: true,
    })
    return (
        <>
            <div ref={ref}
                 className={`${inView ? animate : ''}`}>
                {children}
            </div>
        </>
    )
}
export default Box
