import { Helmet } from "react-helmet";

import BG from "../images/lever/bg.jpg";

import S1 from "../images/lever/content_01.png";
import S2 from "../images/lever/content_02.png";
import S3 from "../images/lever/content_03.png";
import S4 from "../images/lever/content_04.png";
import S5 from "../images/lever/content_05.png";
import S6 from "../images/lever/content_06.png";

import S71 from "../images/lever/content_07_01.png";
import S72 from "../images/lever/content_07_02.png";
import S73 from "../images/lever/content_07_03.png";
import S74 from "../images/lever/content_07_04.png";
import S75 from "../images/lever/content_07_05.png";

import C1 from "../images/lever/活動商品點數計算_0.jpg";
import C2 from "../images/lever/槓桿_累贈勵對照表_0.jpg";

import Memo from "../images/lever/槓桿文字_0.png";

import Box from "../components/Box";
import { ImageMap } from "@qiuz/react-image-map";
import { useState } from "react";
import { ArrowDownCircleIcon } from "@heroicons/react/16/solid";

import LeverKv from "../components/LeverKv";

const Lever = () => {
  const [show, setShow] = useState(1);
  const [showArticle, setShowArticle] = useState(false);

  const OpenIt = () => {
    const link = document.querySelector("a[id='openIt']");
    link.addEventListener(
      "click",
      (event) => {
        openRequestedTab(link.href);
        event.preventDefault();
      },
      false,
    );
    link.click();
  };

  let windowObjectReference = null; // global variable
  function openRequestedTab(url, windowName) {
    if (windowObjectReference === null || windowObjectReference.closed) {
      windowObjectReference = window.open(url, windowName);
    } else {
      windowObjectReference.focus();
    }
  }

  const mapArea = [
    {
      top: "16%",
      left: "4.2%",
      height: "10%",
      width: "16%",
      style: {
        // background: "rgba(0, 255, 0, .5)",
        cursor: "pointer",
      },
      onMouseOver: () => setShow(1),
    },
    {
      top: "16%",
      left: "22.8%",
      height: "10%",
      width: "16%",
      style: {
        // background: "rgba(0, 255, 0, .5)",
        cursor: "pointer",
      },
      onMouseOver: () => setShow(2),
    },
    {
      top: "16%",
      left: "41.4%",
      height: "10%",
      width: "16%",
      style: {
        // background: "rgba(0, 255, 0, .5)",
        cursor: "pointer",
      },
      onMouseOver: () => setShow(3),
    },
    {
      top: "16%",
      left: "60%",
      height: "10%",
      width: "16%",
      style: {
        // background: "rgba(0, 255, 0, .5)",
        cursor: "pointer",
      },
      onMouseOver: () => setShow(4),
    },
    {
      top: "16%",
      left: "79.2%",
      height: "10%",
      width: "16%",
      style: {
        // background: "rgba(0, 255, 0, .5)",
        cursor: "pointer",
      },
      onMouseOver: () => {
        setShow(5);
      },
    },
    {
      top: "88%",
      left: "44%",
      height: "8%",
      width: "21.5%",
      style: {
        // background: "rgba(0, 255, 0, .5)",
        cursor: "pointer",
      },
      onMouseOver: () => {},
    },
  ];

  const mapDragon = [
    {
      top: "86%",
      left: "14.5%",
      height: "5%",
      width: "28%",
      style: {
        // background: "rgba(0, 255, 0, .5)",
        cursor: "pointer",
      },
    },
  ];

  const OpenImg = () => {
    const selector = "a[id='img']";
    console.log(selector);
    const link = document.querySelector(selector);
    console.log(link);
    link.addEventListener(
      "click",
      (event) => {
        openRequestedTab(link.href);
        event.preventDefault();
      },
      false,
    );
    link.click();
  };

  return (
    <>
      <Helmet>
        <title>{`${process.env.REACT_APP_TITLE}`} - 槓桿活動</title>
        <meta
          name={`description`}
          content={`${process.env.REACT_APP_DESCRIPTION}`}
        />
        <meta
          name={`og:title`}
          content={`${process.env.REACT_APP_TITLE} - 槓桿活動`}
        />
      </Helmet>

      <LeverKv />

      <div
        className={``}
        style={{
          backgroundImage: `url(${BG})`,
        }}
      >
        <div className="container pt-16 max-w-5xl m-auto pl-4 pr-4 pb-10 lg:pb-40">
          <Box animate={`animate__animated animate__fadeIn`}>
            <div className={``}>
              <div className={`text-white text-md lg:text-xl leading-10`}>
                <div>
                  <span className={`font-bold`}>ㄧ.活動主題：</span>元龍騰耀
                  大張期股
                </div>
                <div>
                  <span className={`font-bold`}>二.活動時間：</span>
                  2024/01/22-02/16 (共計{" "}
                  <span className={`text-yellow-300 text-2xl`}>
                    26天，20個交易日
                  </span>
                  )
                </div>
                <div>
                  <span className={`font-bold`}>三.活動商品：</span>
                  元大槓桿差價契約全商品
                </div>
                <div>
                  <div>
                    <span className={`font-bold`}>四.活動資格：</span>
                    (1) 本活動以每人(歸戶ID)作為交易量及獎品兌換單位。
                  </div>
                  <ul className={`lg:ml-32`}>
                    <li>
                      <div className="flex">
                        <div>(2)&nbsp;</div>
                        <div>
                          <span className={`text-yellow-300 font-bold`}>
                            新開戶資格 ﹣&nbsp;
                          </span>
                          2024/01/01-01/31期間於元大期貨或所屬IB完成開立期貨帳戶者，首次交易即為符合資格。
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div>(3)&nbsp;</div>
                        <div>
                          {" "}
                          <span className={`text-yellow-300 font-bold`}>
                            靜止戶資格 ﹣&nbsp;
                          </span>
                          -已於元大期貨或所屬IB開立帳戶，且2023/10/01-2024/01/21從未交易活動商品者，即符合資格。
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div>
                  <span className={`font-bold`}>五. 活動商品點數計算：</span>
                  <img src={S1} alt="" className={`m-auto pt-4`} />
                </div>
              </div>
            </div>
          </Box>

          {S2 && (
            <Box animate={`animate__animated animate__backInLeft`}>
              <img
                src={S2}
                alt="大發抽"
                className={`lg:pt-10 lg:pb-10 pt-4 pb-4`}
              />
            </Box>
          )}

          {S3 && (
            <Box animate={`animate__animated animate__backInRight`}>
              <img
                src={S3}
                alt="大發抽"
                className={`lg:pt-10 lg:pb-10 pt-4 pb-4`}
              />
            </Box>
          )}

          {S4 && (
            <Box animate={`animate__animated animate__backInLeft`}>
              {/*<img*/}
              {/*  src={S4}*/}
              {/*  alt="大發抽"*/}
              {/*  className={`lg:pt-10 lg:pb-10 pt-4 pb-4`}*/}
              {/*/>*/}
              <ImageMap
                className={`lg:pt-10 lg:pb-10 pt-4 pb-4`}
                src={S4}
                map={mapDragon}
                onMapClick={(area, index) => {
                  OpenImg();
                }}
              />
              <a href={`./槓桿_累贈勵對照表.jpg`} id={`img`}></a>
            </Box>
          )}

          {S5 && (
            <Box animate={`animate__animated animate__backInRight`}>
              <img
                src={S5}
                alt="大發抽"
                className={`lg:pt-10 lg:pb-10 pt-4 pb-4`}
              />
            </Box>
          )}

          {S6 && (
            <Box animate={`animate__animated animate__backInLeft`}>
              <img
                src={S6}
                alt="大發抽"
                className={`lg:pt-10 lg:pb-10 pt-4 pb-4`}
              />
            </Box>
          )}

          <Box animate={`animate__animated animate__backInRight`}>
            <div className={`relative`}>
              {/*<img src={S8} alt="大發抽" className={`lg:pt-10 lg:pb-10 pt-4 pb-4`}/>*/}
              <ImageMap
                src={S71}
                className={`${show !== 1 ? "hidden" : ""}`}
                map={mapArea}
                onMapClick={(area, index) => {
                  if (index === 5) OpenIt();
                }}
              />
              <ImageMap
                src={S72}
                className={`${show !== 2 ? "hidden" : ""}`}
                map={mapArea}
                onMapClick={(area, index) => {
                  if (index === 5) OpenIt();
                }}
              />
              <ImageMap
                src={S73}
                className={`${show !== 3 ? "hidden" : ""}`}
                map={mapArea}
                onMapClick={(area, index) => {
                  if (index === 5) OpenIt();
                }}
              />
              <ImageMap
                src={S74}
                className={`${show !== 4 ? "hidden" : ""}`}
                map={mapArea}
                onMapClick={(area, index) => {
                  if (index === 5) OpenIt();
                }}
              />
              <ImageMap
                src={S75}
                className={`${show !== 5 ? "hidden" : ""}`}
                map={mapArea}
                onMapClick={(area, index) => {
                  if (index === 5) OpenIt();
                }}
              />
              <a
                href={`./pdf/6._2024春節不打烊Salekits_(槓桿).pdf`}
                id={`openIt`}
              ></a>
            </div>
          </Box>
        </div>
        <div
          className={`p-4 lg:p-10 bg-gradient-to-r from-cyan-500 to-blue-500`}
        >
          <a
            className={`hover:cursor-pointer inline text-center`}
            onClick={(e) => {
              e.preventDefault();
              setShowArticle(!showArticle);
            }}
          >
            <h3
              className={`text-white text-xl lg:text-3xl text-center inline-flex w-full items-center justify-center`}
            >
              活動注意事項{" "}
              <ArrowDownCircleIcon className={`h-8 w-8 animate-pulse ml-4`} />
            </h3>
          </a>

          <article
            className={`${
              showArticle ? "" : "hidden"
            } pt-10 lg:pt-20 prose lg:prose-xl text-white max-w-none pb-10 lg:pb-20`}
          >
            <img src={Memo} alt={``} />
            {/*<ul>*/}
            {/*  <li>*/}
            {/*    <p>活動資格：</p>*/}

            {/*    <ol>*/}
            {/*      <li>本活動以每人(ID歸戶)作為交易量統計及獎品兌換單位。</li>*/}
            {/*      <li>*/}
            {/*        新開戶定義(以ID歸戶計算)-*/}
            {/*        2024/01/01-01/31期間於元大期貨槓桿交易商完成開立槓桿交易帳戶者，首次交易即符合資格。*/}
            {/*      </li>*/}
            {/*      <li>*/}
            {/*        靜止戶-已有元大期貨槓桿交易帳戶，且2023/10/01-2024/01/21從未交易活動商品者，即符合資格。*/}
            {/*      </li>*/}
            {/*      <li>*/}
            {/*        本活動資格包含員工戶，但不包含法人戶以及中華民國稅法規定非中華民國境內居住之個戶(即在中華民國境內居住未達183天之本國人及外國人)。*/}
            {/*      </li>*/}
            {/*    </ol>*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    <p>*/}
            {/*      活動商品：元大槓桿交易商差價契約全商品（以下簡稱CFD商品）。*/}
            {/*    </p>*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    <p>活動商品點數計算：</p>*/}
            {/*    <img src={C1} alt="" />*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    <p>活動計算說明：</p>*/}
            {/*    <ul>*/}
            {/*      <li>*/}
            {/*        1. 龍年大發抽黃金牌*/}
            {/*        <ul>*/}
            {/*          <li>*/}
            {/*            1)*/}
            {/*            活動期間，指定活動商品交易1點，即獲得一次抽獎機會，每ID最多獲得乙次抽獎機會，且每ID限中獎乙次。*/}
            {/*          </li>*/}
            {/*          <li>*/}
            {/*            2)*/}
            {/*            統一於隔月10日前公告於本公司活動網頁中獎名單，抽出一名獲得元大期貨訂製-龍年黃金牌(價值12,600元)。*/}
            {/*            龍 Time no see-*/}
            {/*            <ul>*/}
            {/*              <li>*/}
            {/*                1)*/}
            {/*                活動期間，新戶或靜止戶交易2點，即獲得元大期貨訂製-悠遊卡200元。*/}
            {/*                -新開戶資格-2024/01/01-01/31期間於元大期貨槓桿交易商完成開立槓桿交易帳戶者，首次交易即符合資格。*/}
            {/*                -靜止戶資格-已有元大期貨槓桿交易帳戶，且2023/10/01-2024/01/21從未交易活動商品者，即符合資格。*/}
            {/*              </li>*/}
            {/*              <li>*/}
            {/*                2) 每ID交易口數歸戶合計計算，且每ID限兌領乙次。*/}
            {/*              </li>*/}
            {/*              <li>*/}
            {/*                3)*/}
            {/*                元大期貨訂製-悠遊卡200元於活動結束後(2024年2月)由所屬業代進行聯繫，客戶需填寫得獎確認單後，於2023年3月底前寄至客戶指定地址。*/}
            {/*                龍騰步步高 好禮龍厚哩-*/}
            {/*                活動期間，依照所有戶的交易狀況，可獲得累贈不累計的交易獎勵。*/}
            {/*                範例：交易點數達第一步贈【百貨禮券1,000元】+*/}
            {/*                【元大期貨訂製-悠遊卡200元】；交易點數達第二步再累贈【百貨禮券9,000元】，第一步、第二步禮品皆可獲得，以此類推。，且每ID限兌領乙次。*/}
            {/*              </li>*/}
            {/*            </ul>*/}
            {/*            <img src={C2} alt="" />*/}
            {/*          </li>*/}
            {/*        </ul>*/}
            {/*      </li>*/}
            {/*    </ul>*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    <p>贈品兌獎說明：</p>*/}
            {/*    <ul>*/}
            {/*      <li>*/}
            {/*        1.*/}
            {/*        百貨紙本禮券-活動結束後結算獲獎資格，依符合獲獎資格配發指定面額之禮券，並將於活動結束後(2024年2月)由所屬業代進行聯繫，客戶需填寫得獎確認單後，於2023年3月底前寄至客戶指定地址。*/}
            {/*      </li>*/}
            {/*      <li>*/}
            {/*        2.*/}
            {/*        元大期貨訂製悠遊卡200元-於活動結束後(2024年2月)由所屬業代進行聯繫，客戶需填寫得獎確認單後，於2023年3月底前寄至客戶指定地址。*/}
            {/*      </li>*/}
            {/*    </ul>*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    <p>其它注意事項：</p>*/}
            {/*    <ul>*/}
            {/*      <li>*/}
            {/*        1.*/}
            {/*        本活動電子禮券與百貨或超市禮券之贈品使用方式與相關限制，悉依該獎項所載附之使用說明及相關須知為準，若有疑問請洽商品廠商或服務提供者，如因使用本獎項而引起之爭議、糾紛、損害賠償，由商品廠商或服務提供者負責。*/}
            {/*      </li>*/}
            {/*      <li>*/}
            {/*        2.*/}
            {/*        本活動依中華民國稅法規定，得獎人如在中華民國境內有固定營業場所之營利事業，或為中華民國境內居住之個人，且獎項超過新台幣2萬元，本公司將代扣繳10%所得稅。如非中華民國境內居住之個人，或在中華民國境內無固定營業場所之營利事業，不論得獎人所得之金額，將代扣繳20%稅金。*/}
            {/*      </li>*/}
            {/*      <li>*/}
            {/*        3.*/}
            {/*        參加本活動所提供之個人資料，視同個人資料保護法授權同意可做本公司於本活動之得獎資料核對、領獎聯絡通知、獎品兌換領取等使用，無法提供個人資料的參加者或得獎者視同放棄獎項，不再另行通知，亦不補發。*/}
            {/*      </li>*/}
            {/*      <li>*/}
            {/*        4.*/}
            {/*        中獎人(納稅義務人)於本公司(扣繳義務人)之全年中獎總金額超過新台幣1,000*/}
            {/*        元(含)以上，年底時元大期貨將依稅法相關規定辦理開立扣繳憑單。*/}
            {/*      </li>*/}
            {/*      <li>*/}
            {/*        5.*/}
            {/*        本活動之贈品不得轉讓，不得要求更換其他款式、規格或以現金折抵贈品價格，亦不適用其他促銷專案，本公司就活動獎項內容得依實際狀況斟酌調整，並保留隨時修改、變更、終止或暫停本活動及替換其他等值贈品之權利，得獎者應配合元大期貨相關公關活動宣傳，若有任何疑問，請洽元大期貨24小時客服專線*/}
            {/*        0800-333-338。*/}
            {/*      </li>*/}
            {/*      <li>*/}
            {/*        6.*/}
            {/*        本活動係以客戶實際成交資料為統計依據，參加活動者需自負盈虧，報名前應先了解相關法令及風險預告書，且投資具有一定之風險，參加者不得以不知其規定為由，提出任何主張，亦不得請求活動期間產生損失之賠償。*/}
            {/*      </li>*/}
            {/*    </ul>*/}
            {/*  </li>*/}
            {/*</ul>*/}
          </article>
        </div>
      </div>
    </>
  );
};
export default Lever;
