import { Outlet } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { HelmetProvider } from "react-helmet-async";
import { RecoilRoot } from "recoil";
import { Suspense } from "react";
import { RootRenderLoading } from "./utils";
import StickyFooter from "./components/StickyFooter";
import StickyTop from "./components/StickyTop";
import MyModal from "./components/MyModal";
import MyModal2 from "./components/MyModal2";

const Layout = () => {
  return (
    <>
      <HelmetProvider>
        <Header />
        <RecoilRoot>
          <Suspense fallback={<RootRenderLoading />}>
            <main className={``}>
              <StickyTop />
              <Outlet />
              <a
                onClick={(e) => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                className={`fixed right-4 bottom-10 z-30 w-14 cursor-pointer`}
              >
                <img src="./img/gtop.png" alt="top" />
              </a>
            </main>
            <StickyFooter />
            <MyModal2 />
            <Footer />
          </Suspense>
        </RecoilRoot>
      </HelmetProvider>
    </>
  );
};
export default Layout;
