import K1 from "../images/lever/kv.jpg";
import K2 from "../images/lever/kv_前景.png";
import K3 from "../images/lever/kv.png";

const LeverKv = () => {
  return (
    <div className={`pt-[40px] md:pt-0 lg:pt-0`}>
      {K1 && K2 && K3 && (
        <div
          className={`bg-cover bg-center bg-no-repeat`}
          style={{
            backgroundImage: `url(${K1})`,
          }}
        >
          <div className={`relative`}>
            <img
              src={K3}
              alt="動物？"
              className={`animate__animated animate__zoomIn m-auto`}
            />
            <img
              src={K2}
              alt="cloud"
              className={`absolute bottom-0 animate__animated animate__fadeInUp w-full`}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default LeverKv;
