import {ArrowDownIcon} from "@heroicons/react/24/outline/index.js";

export const coming = "  春節不打烊 重磅推出\r\n" +
    "     總獎金上百萬     \r\n" +
    "     活動敬請期待…     ";
export default function Avatar({avatar, department, name, title, body}) {
    return (
        <>
            <div className="flex items-start md:items-start justify-center bg-gray-100 rounded-lg p-4">
                <img src={avatar} alt={name} className={`w-[80px] md:w-[146px]`}/>
                <div className={`ml-4 mr-4 leading-0 lg:leading-10`}>
                    <p className={`text-primary`}>
                        <span className={`text-sm md:text-xl`}>{department}</span>
                        <br/>
                        <span className={`text-xl md:text-2xl`}>{name}</span> {title}
                    </p>
                    <p className={`mt-2 text-xs md:text-md`}>{body}</p>
                </div>
            </div>
        </>
    );
}

/***
 * Root Render.
 * @returns {JSX.Element}
 * @constructor
 */
export const RootRenderLoading = () => {
    return (
        <div
            className={`top-50 flex min-h-screen flex-col content-center justify-center bg-gradient-to-r from-indigo-400/90 to-cyan-400/90`}
        >
            <div
                className={`m-auto w-[140px] rounded-xl bg-gradient-to-r from-slate-900 to-slate-700 p-4`}
            >
                <div className={`font-md text-center leading-loose text-white`}>
                    <div className={`inline-flex items-center`}>
                        <ArrowDownIcon
                            className={`rounded-ful mr-2 h-4 w-4 animate-pulse stroke-sky-500`}
                        />
                        <span>Loading ...</span>
                    </div>
                </div>
            </div>
        </div>
    );
};
